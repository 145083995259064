@import '../scss/index';

.tippy {
  &-box {
    background: rgba($white, 0.05);
    backdrop-filter: blur(52px);
    border-radius: 0;

    &[data-placement^='top'] > .tippy-arrow:before {
      bottom: -8px;
      left: 0;
      border-width: 8px 8px 0;
      border-top-color: initial;
      transform-origin: center top;
    }
  }

  &-arrow {
    color: rgba($white, 0.05);
  }

  &-content {
    padding: 10px;
  }
}
