//colors
$black: #101828;
$white: #fff;
$gray: #847a89;
$primary-500: #1EAB88;
$primary-600: #118A6F;
$primary-800: #0D5849;
$turquoise: #00e1c8;
$error: #F04438;
$success: #12B76A;
$orang: #F79009;
$yellow: #EAAA08;
$blue: #2E90FA;

//transition
$transitionEase: all 0.15s ease;

$backgroundDropDown: rgba(0, 0, 0, 0.05);
