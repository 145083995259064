.layoutWrapper {
  display: flex;
  min-height: 100vh;

  .menu {
    width: 290px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .content {
    width: calc(100vw - 290px);
    padding: 30px 20px;
    margin-left: auto;
  }

  .fullContent {
    width: 100vw;
  }
}
